import BasePage from "../modules/basePage";
import Alpine from "alpinejs";
import vimeoPlayer from "../modules/video/vimeo";
class DefaultPage extends BasePage {
    constructor(...opts) {
        Alpine.data("vimeoPlayer", vimeoPlayer);
        super(...opts);
    }

    init() {
        super.init();
    }

    onReady() {
        super.onReady();
    }
}

new DefaultPage();
